import React, { useCallback, useEffect, useRef, useState } from 'react';
import Head from 'next/head';
import { useRouter } from 'next/router';
import {
  Button,
  Box,
  Flex,
  Image,
  useDisclosure,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  position,
  Center,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalCloseButton,
  List,
  ListItem,
  Radio,
  RadioGroup
} from '@chakra-ui/react';
import { useToast } from '@/web/common/hooks/useToast';
import { useSystemStore } from '@/web/common/system/useSystemStore';
import { useQuery } from '@tanstack/react-query';
import { streamFetch } from '@/web/common/api/fetch';
import { useShareChatStore } from '@/web/core/chat/storeShareChat';
import SideBar from '@/components/SideBar';
import { gptMessage2ChatType } from '@/utils/adapt';
import { getErrText } from '@fastgpt/global/common/error/utils';
import type { ChatHistoryItemType, ChatSiteItemType } from '@fastgpt/global/core/chat/type.d';
import { customAlphabet } from 'nanoid';
const nanoid = customAlphabet('abcdefghijklmnopqrstuvwxyz1234567890', 12);

import ChatBox, { type ComponentRef, type StartChatFnProps } from '@/components/ChatBox';
import PageContainer from '@/components/PageContainer';
import ChatHeader from './components/ChatHeader';
import ChatCompare from './components/ChatCompare';
import ChatHistorySlider from './components/ChatHistorySlider';
import { serviceSideProps } from '@/web/common/utils/i18n';
import { checkChatSupportSelectFileByChatModels } from '@/web/core/chat/utils';
import { useTranslation } from 'next-i18next';
import { getInitOutLinkChatInfo, getStandardList } from '@/web/core/chat/api';
import { POST } from '@/web/common/api/request';
import { chatContentReplaceBlock } from '@fastgpt/global/core/chat/utils';
import { useChatStore } from '@/web/core/chat/storeChat';
import { ChatStatusEnum } from '@fastgpt/global/core/chat/constants';
import MyBox from '@/components/common/MyBox';

const OutLink = ({ authToken, chatId }: { authToken?: string; chatId: string }) => {
  const { t } = useTranslation();
  const router = useRouter();
  const { toast } = useToast();
  const finalRef = React.useRef(null);
  const { isOpen: isOpenSlider, onClose: onCloseSlider, onOpen: onOpenSlider } = useDisclosure();
  const { isOpen: isOpenSelect, onClose: onCloseSelect, onOpen: onOpenSelect } = useDisclosure();
  const { isPc } = useSystemStore();
  const ChatBoxRef = useRef<ComponentRef>(null);
  const forbidRefresh = useRef(false);
  const initSign = useRef(false);
  const [isEmbed, setIdEmbed] = useState(true);
  const [standardId, setStandardId] = useState('');
  const [chatTitle, setChatTitle] = useState('');
  const standardList = useRef<any[]>([]);
  const newChatId = useRef('');
  const isFirst = useRef(true);

  //是否是制度检查APP
  const isCheckApp = () => {
    return appId == '658fc6bb37d78a82911d9d75';
  };

  //危险货物，应用ID:6587080bbd4793d0c1379b74,shareId:5ekzd51lsqg9akdkqasnddwd
  //制度检查，用用ID:658fc6bb37d78a82911d9d75,shareId:syvetxdj0u0akuyesw4mk6oa
  const {
    localUId,
    shareChatHistory, // abandon
    clearLocalHistory // abandon
  } = useShareChatStore();
  const {
    histories,
    loadHistories,
    pushHistory,
    updateHistory,
    delOneHistory,
    lastChatAppId,
    lastChatId,
    chatData,
    setChatData,
    delOneHistoryItem,
    clearHistories
  } = useChatStore();
  const appId = lastChatAppId;
  const shareId = lastChatId;
  const outLinkUid: string = authToken || localUId;
  const showHistory = '1';

  const onChangeChat = (chatId: any, isAdd = false) => {
    if (isAdd && isCheckApp()) {
      //新增制度检查 -- 选择检查制度
      console.log('新增制度检查');
      isFirst.current = true;
      onOpenSelect();
      return;
    }
    router.replace({
      query: {
        ...router.query,
        chatId: chatId || ''
      }
    });
    if (!isPc) {
      onCloseSlider();
    }
  };

  const startChat = useCallback(
    async ({ messages, controller, generatingMessage, variables }: StartChatFnProps) => {
      const prompts = messages.slice(-2);
      let completionChatId = '';
      if (variables.standardId) {
        //如果是第一条消息
        if (isFirst.current) {
          newChatId.current = nanoid();
        }
        completionChatId = newChatId.current;
      } else {
        isFirst.current = false;
        completionChatId = chatId ? chatId : nanoid();
        newChatId.current = completionChatId;
      }
      let newTitle = '';
      if (isCheckApp()) {
        newTitle = standardList.current.find((v) => v.standard_id == standardId)?.name || '新检查';
        variables.chatTitle = newTitle;
        setChatTitle(newTitle);
      } else {
        setChatTitle('');
        newTitle =
          chatContentReplaceBlock(prompts[0].content).slice(0, 20) ||
          prompts[1]?.value?.slice(0, 20) ||
          '新对话';
      }
      const { responseText, responseData } = await streamFetch({
        data: {
          messages: prompts,
          variables,
          shareId,
          chatId: completionChatId,
          outLinkUid
        },
        onMessage: generatingMessage,
        abortSignal: controller
      });
      // new chat
      if (completionChatId !== newChatId.current || isFirst.current) {
        const newHistory: ChatHistoryItemType = {
          chatId: completionChatId,
          updateTime: new Date(),
          title: newTitle,
          appId,
          top: false
        };
        pushHistory(newHistory);
        if (isCheckApp()) {
          updateHistory({
            ...newHistory,
            updateTime: new Date(),
            title: newTitle,
            customTitle: newTitle,
            shareId,
            outLinkUid
          });
        }
        if (controller.signal.reason !== 'leave') {
          forbidRefresh.current = true;
          router.replace({
            query: {
              ...router.query,
              chatId: completionChatId
            }
          });
        }
        if (isFirst.current) {
          isFirst.current = false;
        }
      } else {
        // update chat
        const currentChat = histories.find((item) => item.chatId === newChatId.current);
        console.log(histories, 'currentChat 更新', newChatId.current);
        currentChat &&
          updateHistory({
            ...currentChat,
            updateTime: new Date(),
            title: newTitle,
            customTitle: newTitle,
            shareId,
            outLinkUid
          });
      }

      if (!isCheckApp()) {
        // update chat window
        setChatData((state) => ({
          ...state,
          title: newTitle,
          history: ChatBoxRef.current?.getChatHistories() || state.history
        }));

        /* post message to report result */
        const result: ChatSiteItemType[] = gptMessage2ChatType(prompts).map((item) => ({
          ...item,
          status: 'finish'
        }));
        result[1].value = responseText;
        result[1].responseData = responseData;

        window.top?.postMessage(
          {
            type: 'shareChatFinish',
            data: {
              question: result[0]?.value,
              answer: result[1]?.value
            }
          },
          '*'
        );
      } else {
        setChatData((state) => ({
          ...state,
          title: newTitle,
          variables
        }));
      }
      return {
        responseText,
        responseData,
        isNewChat: forbidRefresh.current,
        newChatId: completionChatId,
        newTitle
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [chatId, shareId, outLinkUid, setChatData, appId, pushHistory, router, histories, updateHistory]
  );

  const [standardIndex, setStandardIndex] = useState(-1);
  const getStandardArr = useCallback(async () => {
    standardList.current = await getStandardList();
  }, []);
  //选择完标准--确认
  const onSelectStandard = () => {
    if (standardIndex === -1) return;
    router.replace({
      query: {
        ...router.query,
        chatId: ''
      }
    });
    if (!isPc) {
      onCloseSlider();
    }
    setChatTitle(standardList.current[standardIndex]?.name || '');
    setStandardId(standardList.current[standardIndex]?.standard_id || '');
    onCloseSelect();
  };
  const loadChatInfo = useCallback(
    async (shareId: string, chatId: string) => {
      if (!shareId) return null;
      try {
        const res = await getInitOutLinkChatInfo({
          chatId,
          shareId,
          outLinkUid,
          isAll: isCheckApp()
        });
        const history = res.history.map((item) => ({
          ...item,
          status: ChatStatusEnum.finish
        }));
        if (isCheckApp()) {
          console.log('设置标准ID=====', res.variables.standardId);
          if (res.variables.standardId) {
            setStandardId(res.variables.standardId || '');
          }
          if (res.variables.chatTitle) {
            setChatTitle(res.variables.chatTitle || '');
          }
        }
        setChatData({
          ...res,
          history
        });

        ChatBoxRef.current?.resetHistory(history);
        ChatBoxRef.current?.resetVariables(res.variables);

        // send init message
        if (!initSign.current) {
          initSign.current = true;
          if (window !== top) {
            window.top?.postMessage({ type: 'shareChatReady' }, '*');
          }
        }

        if (chatId && res.history.length > 0) {
          setTimeout(() => {
            ChatBoxRef.current?.scrollToBottom('auto');
          }, 500);
        }
      } catch (e: any) {
        console.log(e);
        toast({
          status: 'error',
          title: getErrText(e, t('core.shareChat.Init Error'))
        });
        if (chatId) {
          router.replace({
            query: {
              ...router.query,
              chatId: ''
            }
          });
        }
      }

      return null;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [outLinkUid, router, setChatData, t, toast]
  );
  const { isFetching } = useQuery(['init', shareId, chatId], () => {
    if (forbidRefresh.current) {
      forbidRefresh.current = false;
      return null;
    }
    return loadChatInfo(shareId, chatId);
  });

  // load histories
  useQuery(['loadHistories', outLinkUid, shareId], () => {
    if (shareId && outLinkUid) {
      return loadHistories({
        shareId,
        outLinkUid
      });
    }
    return null;
  });

  // window init
  useEffect(() => {
    getStandardArr();
    setIdEmbed(window !== top);
  }, [getStandardArr]);

  // todo:4.6.4 init: update local chat history, add outLinkUid
  useEffect(() => {
    const activeHistory = shareChatHistory.filter((item) => !item.delete);
    if (!localUId || !shareId || activeHistory.length === 0) return;
    (async () => {
      try {
        await POST('/core/chat/initLocalShareHistoryV464', {
          shareId,
          outLinkUid: localUId,
          chatIds: shareChatHistory.map((item) => item.chatId)
        });
        clearLocalHistory();
        // router.reload();
      } catch (error) {
        toast({
          status: 'warning',
          title: getErrText(error, t('core.shareChat.Init Error'))
        });
      }
    })();
  }, [clearLocalHistory, localUId, router, shareChatHistory, shareId, t, toast]);

  useEffect(() => {
    console.log(histories, 'histories');
    if (histories.length > 0) {
      onChangeChat(histories[0].chatId);
    } else {
      onChangeChat('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [histories]);
  return (
    <PageContainer {...(isEmbed ? { p: '0 !important', borderRadius: '0' } : {})}>
      <Head>
        <title>{chatData.app.name}</title>
      </Head>
      <MyBox isLoading={isFetching} h={'100%'} display={'flex'} flexDirection={['column', 'row']}>
        {showHistory === '1'
          ? ((children: React.ReactNode) => {
              return isPc ? (
                <SideBar>{children}</SideBar>
              ) : (
                <Drawer
                  isOpen={isOpenSlider}
                  placement="left"
                  autoFocus={false}
                  size={'xs'}
                  onClose={onCloseSlider}
                >
                  <DrawerOverlay backgroundColor={'rgba(255,255,255,0.5)'} />
                  <DrawerContent maxWidth={'250px'} boxShadow={'2px 0 10px rgba(0,0,0,0.15)'}>
                    {children}
                  </DrawerContent>
                </Drawer>
              );
            })(
              <ChatHistorySlider
                appId={appId}
                appName={chatData.app.name}
                appAvatar={chatData.app.avatar}
                activeChatId={chatId}
                history={histories.map((item) => ({
                  id: item.chatId,
                  title: item.title,
                  customTitle: item.customTitle,
                  top: item.top
                }))}
                onClose={onCloseSlider}
                onChangeChat={onChangeChat}
                onDelHistory={({ chatId }) =>
                  delOneHistory({ appId: chatData.appId, chatId, shareId, outLinkUid })
                }
                onClearHistory={() => {
                  clearHistories({ shareId, outLinkUid });
                  router.replace({
                    query: {
                      ...router.query,
                      chatId: ''
                    }
                  });
                }}
                onSetHistoryTop={(e) => {
                  updateHistory({
                    ...e,
                    appId: chatData.appId,
                    shareId,
                    outLinkUid
                  });
                }}
                onSetCustomTitle={async (e) => {
                  updateHistory({
                    appId: chatData.appId,
                    chatId: e.chatId,
                    title: e.title,
                    customTitle: e.title,
                    shareId,
                    outLinkUid
                  });
                }}
              />
            )
          : null}

        {/* chat container */}
        <Flex
          position={'relative'}
          h={[0, '100%']}
          w={['100%', 0]}
          flex={'1 0 0'}
          flexDirection={'column'}
        >
          {/* header */}
          <ChatHeader
            standardId={standardId}
            appAvatar={chatData.app.avatar}
            appName={chatData.app.name}
            chatTitle={chatTitle}
            dateTime={chatData.variables.dateTime}
            appId={appId}
            history={chatData.history}
            showHistory={showHistory === '1'}
            onOpenSlider={onOpenSlider}
          />
          {/* chat box */}
          {appId == '6587080bbd4793d0c1379b74' ? (
            <Box flex={1}>
              <ChatBox
                active={!!chatData.app.name}
                ref={ChatBoxRef}
                appAvatar={chatData.app.avatar}
                userAvatar={chatData.userAvatar}
                userGuideModule={chatData.app?.userGuideModule}
                // showFileSelector={checkChatSupportSelectFileByChatModels(chatData.app.chatModels)}
                feedbackType={'user'}
                onUpdateVariable={(e) => {}}
                onStartChat={startChat}
                onDelMessage={(e) =>
                  delOneHistoryItem({ ...e, appId: chatData.appId, chatId, shareId, outLinkUid })
                }
                appId={chatData.appId}
                chatId={chatId}
                shareId={shareId}
                outLinkUid={outLinkUid}
              />
            </Box>
          ) : (
            <Box flex={1}>
              <ChatCompare
                standardId={standardId}
                appId={chatData.appId}
                chatId={chatId}
                shareId={shareId}
                variables={chatData.variables}
                onStartChat={startChat}
                appAvatar={chatData.app.avatar}
                appName={chatData.app.name}
                history={chatData.history}
                showHistory={showHistory === '1'}
                onOpenSlider={onOpenSlider}
              ></ChatCompare>
            </Box>
          )}
        </Flex>
      </MyBox>
      <Modal finalFocusRef={finalRef} isOpen={isOpenSelect} onClose={onCloseSelect}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>选择检查标准</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <RadioGroup
              value={standardIndex + ''}
              onChange={(index) => {
                setStandardIndex(Number(index));
              }}
            >
              <List spacing={8}>
                {standardList.current.map((item, i) => (
                  <ListItem
                    position={'relative'}
                    p={'10px'}
                    cursor={'pointer'}
                    backgroundColor={'gray.50'}
                    border={'1px solid #eee'}
                    borderColor={i == standardIndex ? 'blue.600' : '#eee'}
                    borderRadius={10}
                    key={i}
                    onClick={() => {
                      setStandardIndex(i);
                    }}
                  >
                    <Box fontWeight={'bold'}>{item.name}</Box>
                    <Box color={'gray.500'}>{item.description}</Box>
                    <Radio
                      size="md"
                      value={i + ''}
                      position={'absolute'}
                      right={'10px'}
                      top={'42%'}
                    ></Radio>
                  </ListItem>
                ))}
              </List>
            </RadioGroup>
          </ModalBody>
          <ModalFooter>
            <Button variant="ghost" onClick={onCloseSelect}>
              关闭
            </Button>
            <Button colorScheme="blue" mr={3} onClick={onSelectStandard}>
              确认
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </PageContainer>
  );
};

export async function getServerSideProps(context: any) {
  const chatId = context?.query?.chatId || '';
  console.log('修改chatId:', chatId);
  return {
    props: {
      chatId,
      ...(await serviceSideProps(context))
    }
  };
}

export default OutLink;
