
    (window.__NEXT_P = window.__NEXT_P || []).push([
      "/chat/share",
      function () {
        return require("private-next-pages/chat/share.tsx");
      }
    ]);
    if(module.hot) {
      module.hot.dispose(function () {
        window.__NEXT_P.push(["/chat/share"])
      });
    }
  